import { CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { Component, effect, Inject, inject, PLATFORM_ID } from '@angular/core';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ CurrencyPipe ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  readonly _order = inject(OrderService)

  total: number = 0

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      effect(() => {
        this.total = this._order.$total();
      })
    }
  }
}
