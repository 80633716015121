<section class="acceso">
  <section>
    <figure>
      <img src="/assets/images/ordenaris.png" alt="Ordenaris">
    </figure>
    <figcaption>
      <h2>Pagos QA</h2>
    </figcaption>
    <section>
      <img src="/assets/images/restringido.png" alt="acceso_restringido">
    </section>
  </section>
</section>
<style type="text/css" media="screen">
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #464646;
    font-family: sans-serif;
    box-sizing: border-box;
    margin: 0px;
  }

  body {
    -webkit-text-size-adjust: none;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-right, 0px);
    width: 100%;
  }

  .acceso {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: auto;
    overflow: hidden;
  }

  .acceso>section {
    width: 90%;
    padding: 10px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(237, 28, 36, 1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(237, 28, 36, 1);
    box-shadow: 0px 0px 4px 1px rgba(237, 28, 36, 1);
  }

  .acceso>section>figure {
    width: 80%;
    margin: auto;
    padding: 10px 0px;
  }

  .acceso>section>figure>img {
    width: 100%;
    height: auto;
  }

  .acceso>section>figcaption {
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .acceso>section>figcaption>h2 {
    width: 100%;
    text-align: center;
  }

  .acceso>section>section {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  .acceso>section>section>img {
    width: 100%;
    height: auto;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .acceso>section {
      width: 60%;
    }
  }

  @media only screen and (min-width: 1025px) {
    .acceso>section {
      width: 30%;
    }
  }
</style>
