import { Routes } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { hasOrderGuard } from './core/guards/has-order.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

export const routes: Routes = [
  {
    path: 'paynow/v1',
    component: LayoutComponent,
    children: [
      {
        path: 'order/:orderId',
        canActivate: [ hasOrderGuard ],
        loadComponent: () => import('./pages/order-validation/order-validation.component').then(m => m.OrderValidationComponent)
      },
      {
        path: 'order/:orderId/invalid',
        loadComponent: () => import('./pages/invalid-order/invalid-order.component').then(m => m.InvalidOrderComponent)
      },
      {
        path: 'order/:orderId/checkout',
        loadComponent: () => import('./pages/checkout/checkout.component').then(m => m.CheckoutComponent)
      },
      {
        path: 'order/:orderId/order-complete',
        loadComponent: () => import('./pages/successful-purchase/successful-purchase.component').then(m => m.SuccessfulPurchaseComponent)
      },
      { path: '', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent },
    ]
  },
  {
    path: '',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    component: UnauthorizedComponent
  }
];
