<header class="">
  <div class="op-container flex items-center">
    <div class="img-container">
      <img src="https://ordenaris.com/img/logo.png" alt="">
    </div>
    @if(total) {
    <div class="">
      <p class="text-xs text-end">Total:</p>
      <p class="text-xl text-[#000] font-semibold">{{total | currency}}</p>
    </div>
    }
  </div>
</header>